<template>
  <el-container class="common-layout">
    <el-header class="header"> 开发工具库 </el-header>
    <el-container>
      <el-aside width="200px" class="aside">
        <el-menu router :default-active="$route.path">
          <el-menu-item index="/">
            <span>首页</span>
          </el-menu-item>
          <el-menu-item index="/jwt/parse">
            <span>JWT解析</span>
          </el-menu-item>
          <el-menu-item index="/jwt/generate">
            <span>JWT生成</span>
          </el-menu-item>
          <el-menu-item index="/tools/code_contrast">
            <span>文本对比</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<style lang="less" scoped>
.common-layout {
  width: 100vw;
  height: 100vh;
  .header {
    background: #444;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .aside {
    background: #444;
  }
}
</style>
