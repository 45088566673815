import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeLayout from "@/layouts/HomeLayout.vue";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeLayout,
    children: [
      {
        path: "/",
        component: HomeView,
      },
      {
        path: "/jwt/parse",
        component: () => import("@/views/jwt/JwtParse.vue"),
      },
      {
        path: "/jwt/generate",
        component: () => import("@/views/jwt/JwtGenerate.vue"),
      },
      {
        path: "/tools/code_contrast",
        component: () => import("@/views/tools/CodeContrast.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
